button {
  margin-top: 20px;
  margin-left: 20px;
  font-size: 1em;
  padding: 5px 10px;
  background: none;
  border: 1px solid rgb(117,117,117);
  border-radius: 5px;
  }
  
button:hover {
  color: white;
  background-color: rgb(117,117,117);
  cursor: pointer;
}

iframe{
  display: block;
  margin: 0 auto;
  width: 900px;
  height: 600px;
}

@media only screen and (max-width: 768px) {
  iframe{
    width: 90%;
  }
}