.posts-container {
  width: 90%;
  margin: 20px auto;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.post-img {
  margin-top: 25px;
  width: 45%;
  height: 100%;
  border-radius: 10px;
}

.post {
  width: 45%;
}

.post-title {
  font-family: "PT Serif";
  font-size: 1.6em;
  text-decoration: underline;
  text-decoration-color: #827397;
  text-decoration-thickness: 6px;
  text-underline-offset: 6px;
  line-height: 1.6;
  margin-bottom: 35px;
}

.appt-btn {
  font-size: 1.2em;
  padding: 4px 11px;
  margin: 15px 0;
  background: none;
  border: 1px solid rgb(0, 113, 248);
  border-radius: 5px;
}

.appt-btn:hover {
  color: white;
  background-color: rgb(0, 113, 248);
  cursor: pointer;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th {
  text-align: left;
}

th, td {
  padding: 8px;
}

tr:nth-child(odd) {
  background-color: rgb(242, 242, 242);
}

@media only screen and (max-width: 768px) {
  .post-img, .post{
    width: 90%;
  }
}
