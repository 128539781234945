video {
  position: fixed;
  top: 0;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.name {
  position: absolute;
  top: 25vh;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
  font-size: 6em;
  color: white;
  font-family: Bangers, serif;
}

.enter-container {
  position: absolute;
  top: 60vh;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  font-size: 2em;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.enter-link {
  color: white;
  font-size: 1.5em;
  font-family: Bangers, serif;
}

@media only screen and (max-width: 768px) {
  .name {
    flex-direction: column;
  }
}