.faq-container {
  width: 80%;
  margin: 70px auto;
  display: flex;
  flex-wrap: wrap;
  row-gap: 30px;
  justify-content: space-around;
}

.faq-img {
  width: 45%;
  border-radius: 15px;
}

@media only screen and (max-width: 768px) {
  .faq-img {
    width: 100%;
  }
}