.background-img {
  position: relative;
  top: 0;
  width: 100%;
  height: 350px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 3;
}

.background-title {
  position: absolute;
  text-align: center;
  top: 3em;
  width: 100%;
  font-size: 3em;
  color: white;
  z-index: 3;
}

.background-color {
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  background-color: rgb(74, 72, 111); 
  z-index: 2;
}

header {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 4;
}

.brand {
  text-decoration: none;
  margin-left: 20px;
  font-size: 1.5em;
  font-family: Bangers, serif;
}

.menu {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  list-style-type: none;
  column-gap: 20px;
  padding: 0;
  margin-top: 13px;
}

a {
  color: white;
  font-weight: bold;
}

.active-link {
  text-decoration: underline;
}

.hamburger {
  display: none;
  margin-right: 10px;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
  background-color: white;
}

.lang-select {
  padding: 0;
  border: none;
  margin: 0 20px;
  margin-left: 0;
  color: white;
}

.lang-select:hover {
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .brand {
    font-size: 1.2em;
  }

  .menu {
    position: fixed;
    top: 30px;
    height: 0px;
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    background-color: white;
    overflow: hidden;
    -webkit-transition: all 0.5s ease;
            transition: all 0.5s ease;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  }

  .menu.active {
    height: 310px;
  }

  a {
    display: block;
    text-align: center;
    padding: 15px 0;
    color: rgb(74, 72, 111);
  }

  .brand {
    color: white;
  }

  .lang-select {
    position: fixed;
    right: 45px;
    top: 15px;
  }

  .hamburger {
    display: block;
    cursor: pointer;
  }
  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }
  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
}