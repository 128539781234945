.contact-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
  margin: 70px auto;
  gap: 20px;
}

.contact-title {
  width: 100%;
  text-align: center;
  font-size: 2em;
}

.contact-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  width: 30%;
  height: 300px;
  border: 1px solid lightgrey;
  box-shadow: 7px 7px 10px lightgray;
}

p {
  margin-top: -10px;
}

.icon {
  width: 20%;
  height: auto;
}

.info {
  width: 65%;
  word-wrap: break-word;
  padding: -20px 0;
}

.code {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 8px;
  text-align: center;
}

.wechat {
  height: 70%;
}

@media only screen and (max-width: 768px) {
  .contact-card {
    width: 90%;
  }

  .wechat {
    height: auto;
    width: 70%;
    padding-bottom: 10px;
  }
}
